.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #F0F0F0;
}
.home-image {
  width: 500px;
  height: 250px;
  z-index: 100;
  align-self: center;
  margin-top: 50px;
  object-fit: cover;
}
.home-image1 {
  width: 768px;
  height: 430px;
  z-index: 99;
  margin-top: -15px;
  object-fit: cover;
}
